import styled from 'styled-components';

function App() {
  return (
    <Container>
      doquiry
    </Container>
  );
}

const Container = styled.div`
  color: blue;
`;

export default App;
